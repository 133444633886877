/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;

  Drupal.behaviors.analyticsBehavior = {
    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    trackHomePageEvents: function() {
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var position = [];
      if (prodcat && prodcat.data && prodcat.data.pids) {
        for (var i = 0; i < productIds.length; i++) {
          position.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return position;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      if (self.attached) {
        return;
      }

      // Track Home Page Events
      if (location.pathname === '/') {
        self.trackHomePageEvents();
      }

      // Track Brand Logo
      $('.site-logo__icon, .icon-logo', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      // Track Product Click
      $(document).on('click', '.js-product-brief-view .js-product-brief', function () {

        $('.js-product-brief-view .js-grid-item-product').each(function(index) {
          $(this).attr('data-index', index);
        });

        var $prodElem = $(this);
        var prodId = 'PROD' + $prodElem.attr('data-product-id');
        var prodPosition = Number($prodElem.closest('.js-grid-item-product').data('index')) + 1;
        var prodName = $prodElem.find('.js-product-display-name-link').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name:[prodName]
          });
        }
      });

      // Product click on Recommendation section
      recomProductSelectors = [
        '.recommended-item a.thumb',
        '.recommended-item .product_name a'
      ];
      $(document).on('click', recomProductSelectors.join(', '), function () {
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.recommended-item').find('.shopnow').data('product-id');
        if (typeof prodId == 'undefined') {
          var $prodHref = $prodElem.attr('href');
          var splitprod = $prodHref.split('/');
          if ($('body').hasClass('en_US')) {
            var prodId = 'PROD' + splitprod[3];
          } else {
            var prodId = 'PROD' + splitprod[5];
          }
        }
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId]
          });
        }
      });

      // Track Quick Shop
      $('.js-quickshop-link', context).on('click', function() {
        var $currentElem = $(this);
        var catName, prodElem, obj, prodId, prodName;
        catName = $('.content > article', context).attr('trackname');
        prodElem = $(this).closest('.product-grid__item');
        prodId = $(this).attr('data-product-id');
        prodName = prodElem.find('.product-thumb__headline').text().replace(/\s+/g, ' ').trim();

        obj = {
          event_label: prodName + ' - ' + $(this).attr('data-product-id'),
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.product-thumb--price').text().replace(/\s+/g, ' ').trim()]
        };
        //product click added here to ensure fires with Quick Shop
        site.track.productClick({
          targetElem: $currentElem,
          product_id: [prodId]
        });
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $(document).on('click', '.js-typeahead-link a', function() {
        var obj;
        var term = $('input#search').val();
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_label: term,
          search_keyword: term,
          product_sku: 'SKU' + $(this).attr('href').split('/')[2],
          product_id: 'PROD' + $(this).attr('href').split('/')[3],
          product_name: self.stripOutMarkup($(this).find('.search-products__title').text())
        };
        site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
      });

      //Track Pony Quiz
      $(document).on('click', '.js-quiz-slide .js-view-result', function() {
        var prodtext = '';
        var obj = '';
        if ($('.pony-picker').hasClass('slick-active')) {
          prodtext = $('.slick-track .slick-active').find('.pony-picker__go-for-text').text();
          obj = {
            modiface_evar: 'Pick your pony | Results |' + ' ' + prodtext,
            page_name: 'Pick your pony | Results |' + ' ' + prodtext
          };
        }
        site.track.evtView(obj);
      });

      // CHECKOUT EVENTS
      // Track Payment Method
      var paymentElements = [
        '#checkout_review input.place-order',
        '#checkout_billing input.js-submit-payment'
      ];
      $(document).on('click', paymentElements.join(','), function() {
        var obj;
        var payment_label = '';
        var payment_type = $('input[name=PAYMENT_METHOD]:checked').val() || $('input[name=PAYMENT_TYPE]:checked').val() || $('input[name=payMethod]:checked').val();
        if (payment_type === 'PP' || payment_type === 'PayPal') {
          payment_label = 'paypal';
        } else if (payment_type === 'CC' || payment_type === 'Credit Card') {
          payment_label = 'creditcard';
        } else if (payment_type === 'UPOP') {
          payment_label = 'UPOP';
        } else if (payment_type === 'ALIPAY') {
          payment_label = 'ALIPAY';
        } else {
          payment_type === 'WECHAT';
        }
        obj = {
          event_label: payment_label
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // Track guest user checkout
      $(document).on('click', '#checkout_signin_guest_user-submit, #checkout_signin_new_user-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $(document).on('click', '#checkout_signin-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Extole Refer A friend
      $(document).on('submit', '.extole-js-email-panel-email-share-form', function() {
        var userEmail = '';
        var name = '';
        var referEmail = '';
        var obj = '';
        var prod = $(this);
        userEmail = prod.find('.extole-input .extole-tokenizer-token').first().text();
        name = prod.find('.extole-js-me-first-name').val();
        referEmail = prod.find('.extole-recipient-emails .extole-tokenizer-token').text();
        if (userEmail && name && referEmail) {
          obj = {
            'event_category': 'Refer_friend',
            'event_action': 'Refer triggered',
            'event_label': 'Refer_Email' + ' - ' + document.location.href,
          };
          site.track.evtLink(obj);
        }
      });

      (function($) {
        var extoleConfirm = [
          '.extole-js-facebook-panel-capture-email-form',
          '.extole-js-twitter-panel-capture-email-form',
          '.extole-js-messenger-panel-capture-email-form'
        ];
        $(document).on('submit', extoleConfirm.join(', '), function() {
          var token = '';
          var obj = '';
          var prod = $(this);
          var referMethod = '';
          referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
          token = prod.find('.extole-tokenizer-token').text();
          if (token) {
            obj = {
              'event_category': 'Refer_friend',
              'event_action': 'Refer triggered',
              'event_label': 'Refer_' + referMethod + ' - ' + document.location.href,
            };
            site.track.evtLink(obj);
          }
        });
      }(jQuery));

      (function($) {
        var extoleDefault = [
          '.extole-js-twitter-panel-share-form',
          '.extole-js-facebook-panel-share-form',
          '.extole-js-messenger-panel-share-form'
        ];
        $(document).on('submit', extoleDefault.join(', '), function() {
          var obj = '';
          var referMethod = '';
          var token = '';
          referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
          token = $('.extole-js-email-panel-email-share-form').find('.extole-tokenizer-token').text();
          if (token) {
            obj = {
              'event_category': 'Refer_friend',
              'event_action': 'Refer triggered',
              'event_label': 'Refer_' + referMethod + ' - ' + document.location.href,
            };
            site.track.evtLink(obj);
          }
        });
      }(jQuery));

      // END CHECKOUT EVENTS

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Trigger Alt image event only when alternate images are available
        if ($('.js-product-gallery__div-thumb--product').length > 1) {
          trackAltImageClick();
        }
      }

      // Track Alt image click - desktop & mobile
      function trackAltImageClick() {
        $(document).on('click', '.js-product-gallery__div-thumb--product', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.js-product-gallery__img-thumb--product').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
        });
      }
      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product__title').text();
        if (prodName) {
          prodName = prodName.trim();
        }
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Mask finder tagging start.
      if ($('body').hasClass('section-mask-finder')) {
        $(document).on('click', '.page-header a.page-header__inner', function() {
          var destinationUrl = $(this).attr('href');
          var eventObj = {};
          eventObj.promo_pos = ['diagnostics tool | Mask Finder'];
          eventObj.promo_creative = ['image'];
          eventObj.promo_name = [destinationUrl];
          eventObj.promo_id = ['diagnostics tool | Mask Finder - ' + destinationUrl];
          trackEvent('diagtools', 'diagnostics tool | Mask Finder', 'button click', 'banner click | ' + destinationUrl, eventObj);
        });
      }
      // Mask finder tagging end.

      // TrackEvent common function call start.
      function trackEvent(eName, eCategory, eAction, elabel, eventObj) {
        eventObj = eventObj || {};
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
      }
      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
